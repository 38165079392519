export default {
  mutations: {
    SET_HOST_CONFIGURATION: 'SET_HOST_CONFIGURATION',
    SET_I18N_LOCAL_MESSAGE: 'SET_I18N_LOCAL_MESSAGE',
  },
  getters: {
    GET_HOST_CONFIGURATION: 'GET_HOST_CONFIGURATION',
  },
  actions: {
    HOST_CONFIGURATION: 'HOST_CONFIGURATION',
  },
};
