export default {
  actions: {
    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_CURRENT_USER: 'RESET_PASSWORD_CURRENT_USER',
    UPDATE_USER_COMPANY: 'UPDATE_USER_COMPANY',
  },
  getters: {
    GET_SINGLE_USER_COMPANY: 'GET_SINGLE_USER_COMPANY',
  },
};
