import { ActionTree } from 'vuex';
import { RootState } from '@/store/interfaces/rootState';
import profileType from '@/store/types/profile';
import { http } from '@/util/HTTP';
import { AxiosResponse, AxiosError } from 'axios';

const namespaced = true;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actions: ActionTree<any, RootState> = {
  [profileType.actions.RESET_PASSWORD_CURRENT_USER]: (_, data) => {
    return new Promise((resolve, reject) => {
      http
        .put('companies/password', { data, })
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [profileType.actions.UPDATE_USER_COMPANY]: (_, userCompanyData) => {
    const { data, } = userCompanyData;
    const dataToSend = {
      data,
    };
    return new Promise((resolve, reject) => {
      http
        .put('companies/user', dataToSend)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  actions,
};
