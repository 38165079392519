/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ifNotAuthenticated } from '../routeValidation';
import AuthenticationLayout from '@/views/Layout/AuthenticationLayout.vue';
const ForgotPassword = () =>
  import(/* webpackChunkName: "ForgotPassword" */ '@/views/ForgotPassword/SendPasswordInstructions.vue');
const ResetPassword = () =>
  import(/* webpackChunkName: "ResetPassword" */ '@/views/ForgotPassword/ResetPassword.vue');

export default {
  path: '/forgot-password',
  component: AuthenticationLayout,
  beforeEnter: ifNotAuthenticated,
  children: [
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword,
    },
    {
      path: '/reset-password/:id',
      name: 'reset-password',
      component: ResetPassword,
    }
  ],
};
