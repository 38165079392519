import store from '@/store';
import authType from '@/store/types/auth';
import { NavigationGuardNext, Route } from 'vue-router';

export const ifNotAuthenticated = (to: Route, from: Route, next: NavigationGuardNext<Vue>): void => {
  if (!store.getters[`authModule/${authType.getters.IS_AUTHENTICATED}`]) {
    if (to.path === '/') {
      next('/login');
    } else {
      next();
    }
    return;
  }
  next('/');
};

export const ifAuthenticated = (to: Route, from: Route, next: NavigationGuardNext<Vue>): void => {
  if (store.getters[`authModule/${authType.getters.IS_AUTHENTICATED}`]) {
    next();
    return;
  }
  next('/login');
};

export const checkPermissions = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): void => {
  const permissions: string[] = to && to.meta && to.meta.permissions ? to.meta.permissions : [] as string[];

  if (permissions.length === 0) {
    next();
    return;
  }

  const serverPermissions = store.getters[`authModule/${authType.getters.PERMISSIONS}`] as string[];
  const hasPermission = permissions
    .every((p: string) => serverPermissions.includes(p));

  if (!hasPermission) {
    next('/403');
  }

  next();
};
