/* eslint-disable @typescript-eslint/no-explicit-any */
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import {
  PermissionGroupCollectionResponse,
  PermissionGroupParams,
  PermissionGroupState
} from '@/store/interfaces/PermissionGroup';

import { RootState } from '@/store/interfaces/rootState';

// Establece los types de los modulos store
import permissionGroupType from '@/store/types/permissionGroup';
import { http } from '@/util/HTTP';
import { AxiosResponse, AxiosError } from 'axios';
const namespaced = true;

const initialState = (): PermissionGroupState => {
  return {
    PermissionGroupsList: {
      data: [],
      pagination: {},
    },
  };
};

const state: PermissionGroupState = initialState();

const getters: GetterTree<PermissionGroupState, RootState> = {
  [permissionGroupType.getters.GET_PERMISSIONS_GROUP_LIST]: state => {
    return state.PermissionGroupsList;
  },
};
const mutations: MutationTree<PermissionGroupState> = {
  reset: (state: any) => {
    const initialStateFields: any = initialState();
    Object.keys(initialStateFields).forEach((key: any) => {
      state[key] = initialStateFields[key];
    });
  },
  [permissionGroupType.mutations.SET_PERMISSIONS_GROUP_LIST]: (
    state,
    states: PermissionGroupCollectionResponse
  ) => {
    const permissionGroup: Array<PermissionGroupParams> = [];
    states.data.map((permission: PermissionGroupParams) => {
      permission.virtual_permission_count = permission.permissions.length;
      permissionGroup.push(permission);
    });
    state.PermissionGroupsList.data = permissionGroup;
    state.PermissionGroupsList.pagination = states.meta.pagination;
  },
};
const actions: ActionTree<PermissionGroupState, RootState> = {
  [permissionGroupType.actions.PERMISSION_GROUP_LIST]: (
    { commit, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get('companies/permission_groups?kind_eq=company')
        .then((res: AxiosResponse) => {
          commit(
            permissionGroupType.mutations.SET_PERMISSIONS_GROUP_LIST,
            res.data
          );
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
