/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import { RouteConfig } from 'vue-router';

const ProfileConfig = () =>
  import(
    /* webpackChunkName: "ProfileConfiguration" */
    '@/views/Profile/Configuration.vue'
  );

const Profile = () =>
  import(
    /* webpackChunkName: "Profile" */
    '@/views/Profile/Profile.vue'
  );

const myProfileRoute: RouteConfig = {
  path: '/',
  component: DashboardLayout,
  children: [
    {
      path: '/configuration',
      name: 'ProfileConfiguration',
      component: ProfileConfig,
      meta: {
        navbarType: 'light',
      },
    },
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: {
        navbarType: 'light',
      },
    }
  ],
};
export default myProfileRoute;
