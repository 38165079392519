import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import dayJsTimezone from 'dayjs/plugin/timezone';
import isWeek from 'dayjs/plugin/isoWeek';
import localize from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(dayJsTimezone);
dayjs.extend(isWeek);
dayjs.extend(localize);
dayjs.locale('es');

export const timezone = process.env.VUE_APP_TIMEZONE;
dayjs.tz.setDefault(timezone);

type tDayJSParam = string | Date | number | null

export const dayJsInstance = (date?: tDayJSParam): dayjs.Dayjs => {
  return dayjs(date).utc();
};

export default class DatesFormat {
  public formatDate(date: string | null | undefined): string {
    if (date) {
      return dayJsInstance(date).format('ll');
    }
    return 'No disponible';
  }

  public formatDateTime(date: string | null | undefined): string {
    if (date) {
      return dayJsInstance(date).format('lll');
    }
    return 'No disponible';
  }

  public formatYear(date: string | null | undefined): string {
    if (date) {
      return dayJsInstance(date).format('MM-DD-YYYY');
    }
    return 'No disponible';
  }
}
