export default {
  mutations: {
    SET_COMMERCE_LIST: 'SET_COMMERCE_LIST',
    SET_COMMERCE_SETTINGS: 'SET_COMMERCE_SETTINGS',

  },
  getters: {
    GET_COMMERCE_LIST: 'GET_COMMERCE_LIST',
    GET_COMMERCE_SETTINGS: 'GET_COMMERCE_SETTINGS',
  },
  actions: {
    COMMERCE_LIST: 'COMMERCE_LIST',
    COMMERCE_SETTINGS: 'COMMERCE_SETTINGS',
  },
};
