import Vue from 'vue';
import authType from '@/store/types/auth';

export default Vue.extend({
  data() {
    return {
      counter: 0,
    };
  },
  computed: {
    companySelected(): string {
      return this.$store.getters[`authModule/${authType.getters.GET_COMPANY}`];
    },
  },
  watch: {
    companySelected: {
      handler(val: string): void {
        if (val) {
          this.$data.counter++;
        }
      },
    },
  },
});
