






















/* eslint-disable no-new */
import { version } from '@/../package.json';
import { companyHost } from '@/consts/companyHost';
import ReloadComponentMixin from '@/mixins/ReloadComponentMixinWithoutClassComponent';
import auth from '@/store/types/auth';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import Vue from 'vue';
import { FadeTransition } from 'vue2-transitions';
import { mapGetters } from 'vuex';
import DashboardNavbar from './DashboardNavbar.vue';

function hasElement(className: string) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className: string) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default Vue.extend({
  name: 'Dashboardlayout',
  components: {
    DashboardNavbar,
    FadeTransition,
  },
  mixins: [ReloadComponentMixin],
  data: () => ({
    logo: `${process.env.VUE_APP_SIDEBAR_LOGO}`,
    companyHost,
    version,
    isMobile: window.innerHeight < 768,
    toggable: false,
  }),

  computed: {
    ...mapGetters({
      permissionServer: `authModule/${auth.getters.PERMISSIONS}`,
      optionsMenu: `authModule/${auth.getters.GET_MENU}`,
    }),
    marginLeft(): Record<string, string> {
      return {
        marginLeft: this.isMobile
          ? this.isMobile
            ? '0px'
            : '95px'
          : this.toggable
            ? '250px'
            : '95px',
      };
    },
  },

  mounted(): void {
    this.isMobileEvaluation();
    this.initScrollbar();
    this.registerResizing();
  },

  methods: {

    isMobileEvaluation(): void {
      this.isMobile = window.innerWidth < 768;
    },

    registerResizing() {
      window.addEventListener('resize', this.updateScreenSize);
    },

    updateScreenSize(val: Event) {
      this.isMobile = (val.target as Window).innerWidth < 768;
    },

    onToggableChange(t: boolean): void {
      this.toggable = t;
    },

    initScrollbar(): void {
      const isWindows = navigator.platform.startsWith('Win');

      if (isWindows) {
        initScrollbar('sidenav');
      }
    },
    sidebarToggle(): void {
      this.toggable = !this.toggable;
    },
  },
  beforeDestroy(): void {
    window.removeEventListener('resize', this.updateScreenSize);
  },
});
