







import Vue from 'vue';
import { ValidationObserver } from 'vee-validate';

export default Vue.extend({
  name: 'TeipForm',
  components: {
    ValidationObserver,
  },
  methods: {
    async submit() {
      this.$emit('submit', true);
    },
  },
});
