export default {
  mutations: {
    SET_ORDER_KINDS: 'SET_ORDER_KINDS',
    SET_PAYMENT_REQUEST_STATUSES: 'SET_PAYMENT_REQUEST_STATUSES',
  },
  getters: {
    GET_ORDER_KINDS: 'GET_ORDER_KINDS',
    GET_PAYMENT_REQUEST_STATUSES: 'GET_PAYMENT_REQUEST_STATUSES',
  },
  actions: {
    ORDER_KINDS: 'ORDER_KINDS_LIST',
    PAYMENT_REQUEST_STATUSES: 'PAYMENT_REQUEST_STATUSES',
  },
};
