import { NavigationGuardNext, RouteConfig, Route } from 'vue-router';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';

import { staffPermissions } from './permissions';
import { checkPermissions } from '../routeValidation';

const StaffHome = () =>
  import(
    /* webpackChunkName: "StaffHome" */
    '@/views/Staff/Home.vue'
  );

const transactionHistoryRoute: RouteConfig = {
  path: '/',
  component: DashboardLayout,
  children: [
    {
      path: 'staff',
      name: 'SaffHome',
      component: StaffHome,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
        checkPermissions(to, from, next);
      },
      meta: {
        navbarType: 'light',
        permissions: staffPermissions,
      },
    }
  ],
};
export default transactionHistoryRoute;
