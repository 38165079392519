


































import NavBar from '@/components/Login/navBar.vue';
import { companyHost } from '@/consts/companyHost';
import Vue from 'vue';

export default Vue.extend({
  name: 'AuthenticationLayout',
  components: { NavBar, },
  data: () => ({
    navTitle: 'teip',
    companyHost,
    logo: `${process.env.VUE_APP_FOOTER_LOGO}`,
    nav: [
      {
        name: 'Personas',
        url: 'https://teip.io/me',
      },
      {
        name: 'Comercios',
        url: 'https://teip.io/biz',
      },
      {
        name: 'Comunidad',
        url: 'https://teip.io/me/comunity',
      },
      {
        name: 'Ayuda',
        url: 'https://teip.io/me/support',
      }
    ],
    copyRight: 'teip. Todos los derechos reservados.',
    company: 'Servo Technologies S.A de C.V.',
    address: 'San Salvador, El Salvador.',
    disclaimer: `Los servicios bancarios son proveídos por bancos aliados de teip.
      La tarjeta prepago Mastercard teip está emitida bajo licencia de patrocinio de Banco Agrícola S.A.`,
    privacityPoliciesUrl: 'http://teip.io/politicas-de-privacidad',
    termsAndConditionUrl: 'http://teip.io/terminos-y-condiciones',
    socialMedia: {
      facebook: 'teip.me',
      x: 'teip.me',
      instagram: 'teip.me',
      tiktok: '@teip_me',
      linkedin: 'teip-technologies',
    },
    playStore: 'https://play.google.com/store/apps/details?id=io.teip.teipapp',
    appStore: 'https://apps.apple.com/us/app/teip/id1556182467',
  }),
});
