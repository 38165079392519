import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import { teipTranslation } from './locales/teip';
import { companyHost } from '@/consts/companyHost';

Vue.use(VueI18n);

const companyTranslations = JSON.parse(
  window.localStorage.getItem('company-translations') || '{}'
);
const messages = teipTranslation as LocaleMessages;

if (Object.entries(companyTranslations).length > 0) {
  messages[companyHost] = {
    name: companyHost,
    menu: companyTranslations[companyHost].menu,
    publicView: { ...companyTranslations.teip.publicView, },
  };
}

export default new VueI18n({
  locale: companyHost,
  fallbackLocale: 'teip',
  silentFallbackWarn: process.env.NODE_ENV === 'production',
  messages,
});
