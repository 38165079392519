<template>
  <glue-card class="mt-4">
    <glue-row>
      <glue-col>
        <slot>
          <h5 v-if="title" class="card-title text-uppercase text-muted mb-0">{{ title }}</h5>
          <span v-if="subTitle" class="h2 font-weight-bold mb-0">{{ subTitle }}</span>
        </slot>
      </glue-col>

      <glue-col v-if="$slots.icon || icon" cols="auto">
        <slot name="icon">
          <div class="icon icon-shape text-white rounded-circle shadow"
               :class="[`bg-${type}`, iconClasses]"
          >
            <i :class="icon"></i>
          </div>
        </slot>
      </glue-col>
    </glue-row>

    <p v-if="$slots.footer" class="mt-3 mb-0 text-sm">
      <slot name="footer">
      </slot>
    </p>
  </glue-card>
</template>
<script>
export default {
  name: 'StatsCard',
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    icon: String,
    title: String,
    subTitle: String,
    iconClasses: [String, Array],
  },
};
</script>
<style></style>
