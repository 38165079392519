/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import { checkPermissions } from '@/router/routeValidation';
import { remotePaymentPermission } from './permissions';

const RemotePaymentHome = () =>
  import(
    /* webpackChunkName: "RemotePaymentHome" */
    '@/views/RemotePayment/RemotePaymentHome.vue'
  );

const transactionHistoryRoute: RouteConfig = {
  path: '/',
  component: DashboardLayout,
  children: [
    {
      path: 'remote-payment',
      name: 'RemotePaymentHome',
      component: RemotePaymentHome,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
        checkPermissions(to, from, next);
      },
      meta: {
        navbarType: 'light',
        permissions: remotePaymentPermission,
      },
    }
  ],
};
export default transactionHistoryRoute;
