import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import { envEvaluation } from './enviromentEvaluation';

const environment = process.env.VUE_APP_SENTRY_ENV || 'dev';
if (envEvaluation()) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE ?? ''),
    tracingOptions: {
      trackComponents: true,
    },
  });
}
