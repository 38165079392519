<template>
  <!-- Header -->
  <div class="header">
    <div class="h-screen text-center">
      <h1 class="not-found text-muted">404!</h1>
      <p class="display-2 text-light">
        Página no encontrada :(
      </p>
      <router-link to="/" class="font-weight-bold text-warning mt-5">Volver a inicio</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
<style lang="scss" scoped>
.not-found {
  font-size: 10rem;
}
</style>
