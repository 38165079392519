export const historyTransactionPermission = ['index_company_payment_requests', 'transactions.transactions'];

export const salesReportPermission = [
  'index_company_sales_summaries',
  'statistics_company_sales_summaries',
  'transactions.summary'
];

export const remotePaymentPermission = ['index_company_orders', 'payments.teiptag'];

export const dinamicPaymentPermission = ['index_company_order_templates', 'payments.dynamic_links'];

export const scanToPayPermission = ['scan_to_pay_company_payment_requests', 'payments.qr'];

export const staffPermissions = ['staff'];

export const supportPermission = ['create_company_supports', 'support'];
