/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ifNotAuthenticated } from '../routeValidation';

import AuthenticationLayout from '@/views/Layout/AuthenticationLayout.vue';
import { RouteConfig } from 'vue-router';
const Login = () =>
  import(/* webpackChunkName: "Login" */ '@/views/Login/Login.vue');
export const LoginRoutes: RouteConfig = {
  path: '/auth',
  component: AuthenticationLayout,
  beforeEnter: ifNotAuthenticated,
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    }
  ],
};
