<template>
  <base-header :class="pClass" type>
    <div class="py-4 flex justify-between">
      <div>
        <h6 class="h2 d-inline-block mb-0">
          {{ tabSelected }}
        </h6>
        <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
          <slot name="nav"></slot>
        </nav>
      </div>
      <div v-if="$slots.button" class="text-right">
        <slot name="button" />
      </div>
    </div>
  </base-header>
</template>

<script>
export default {
  name: 'HeaderBaseTeip',
  props: {
    tabSelected: {
      type: String,
      required: true,
      description: 'Name tab selected',
    },
    pClass: {
      type: String,
      default: '',
    },
  },
};
</script>
