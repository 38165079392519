import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/store/interfaces/rootState';
import resource from '@/store/types/resources';
import { http } from '@/util/HTTP';
import { AxiosResponse, AxiosError } from 'axios';
import { OrderKindParams, PaymentRequestStatusParams, ResourcesState } from '@/store/interfaces/resources';
import { responseCollection } from '../interfaces/generic';

const namespaced = true;
const initialState = (): ResourcesState => {
  return {
    orderKinds: {
      data: [],
      pagination: {},
    },
    paymentRequestStatuses: [],
  };
};

const state: ResourcesState = initialState();
const getters: GetterTree<ResourcesState, RootState> = {
  [resource.getters.GET_ORDER_KINDS]: state => {
    return state.orderKinds;
  },
  [resource.getters.GET_PAYMENT_REQUEST_STATUSES]: state => {
    return state.paymentRequestStatuses;
  },
};

const mutations: MutationTree<ResourcesState> = {
  [resource.mutations.SET_ORDER_KINDS]: (
    state,
    branch: responseCollection<OrderKindParams>
  ) => {
    state.orderKinds.data = branch.data;
    state.orderKinds.pagination = branch.meta.pagination;
  },
  [resource.mutations.SET_PAYMENT_REQUEST_STATUSES]: (
    state,
    statuses: responseCollection<PaymentRequestStatusParams>
  ) => {
    state.paymentRequestStatuses = statuses.data;
  },
};
const actions: ActionTree<ResourcesState, RootState> = {
  [resource.actions.ORDER_KINDS]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('resources/order_kinds')
        .then((res: AxiosResponse) => {
          commit(resource.mutations.SET_ORDER_KINDS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [resource.actions.PAYMENT_REQUEST_STATUSES]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('resources/enums/payment_request_statuses')
        .then((res: AxiosResponse) => {
          commit(resource.mutations.SET_PAYMENT_REQUEST_STATUSES, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
