import authType from '@/store/types/auth';
import { VNode, DirectiveBinding } from 'vue';

/**
 *
 * @param {string[] | string} permissions
 * @param {VNode} vnode
 * @returns boolean
 */
const commerceHasPermissionTo = (permissions, vnode) => {
  if (vnode.context) {
    const permissionsFromServer = vnode.context.$store.getters[`authModule/${authType.getters.PERMISSIONS}`];

    if (typeof permissions === 'string') {
      return permissionsFromServer.some((val) => val === permissions);
    }
    if (Array.isArray(permissions)) {
      return permissions.every((permission) => permissionsFromServer.includes(permission));
    }
  }
  return new Error(`vnode.context is undefined for: ${JSON.stringify(permissions)}`);
};

/**
 * @param {HTMLElement} el
 * @returns void
 * */
const removeElement = (el) => {
  const parentNode = el.parentNode;
  if (parentNode) {
    parentNode.removeChild(el);
  }
};

export default {
  /**
   *
   * @param {HTMLElement} el
   * @param {DirectiveBinding} binding
   * @param {VNode} vnode
   */
  bind: function (el, binding, vnode) {
    const { value, } = binding;

    const hasPermission = commerceHasPermissionTo(value, vnode);

    if (!hasPermission) {
      removeElement(el);
    }
  },
  /**
   *
   * @param {HTMLElement} el
   * @param {DirectiveBinding} binding
   * @param {VNode} vnode
  */
 update: function (el, binding, vnode) {
    const { value, } = binding;

    const hasPermission = commerceHasPermissionTo(value, vnode);

    if (!hasPermission) {
      removeElement(el);
    }
  },
};
