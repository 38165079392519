const currencyKeyboardEventValidation = (e: KeyboardEvent, value: number | string): void => {
  const valueAsArray = String(value).split('.');

  if (e.key === '.' && valueAsArray.length <= 1) return;

  if ((!isNaN(Number(e.key)) && valueAsArray[1] !== undefined && valueAsArray[1].length < 2)) return;

  if (!isNaN(Number(e.key)) && valueAsArray.length === 1) return;

  e.preventDefault();
};

export { currencyKeyboardEventValidation };
