/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import { checkPermissions } from '../routeValidation';
import {
  dinamicPaymentPermission,
  historyTransactionPermission,
  salesReportPermission,
  scanToPayPermission
} from './permissions';

const TransactionHistory = () =>
  import(
    /* webpackChunkName: "TransactionHistory" */
    '@/views/Sales/Transactions.vue'
  );

const SalesResport = () =>
  import(
    /* webpackChunkName: "SalesReportHome" */
    '@/views/Sales/SalesReport.vue'
  );
const transactionHistoryRoute: RouteConfig = {
  path: '/',
  component: DashboardLayout,
  children: [
    {
      path: '/transaction-history',
      name: 'TransactionHistory',
      component: TransactionHistory,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
        checkPermissions(to, from, next);
      },
      meta: {
        navbarType: 'light',
        permissions: historyTransactionPermission,
      },
    },
    {
      path: '/sales-report',
      name: 'salesReport',
      component: SalesResport,
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
        checkPermissions(to, from, next);
      },
      meta: {
        navbarType: 'light',
        permissions: salesReportPermission,
      },
    },
    {
      path: '/scan-to-pay',
      name: 'ScanToPay',
      component: () =>
        import(
          /* webpackChunkName: "ScanToPay" */
          '@/views/Sales/ScanToPay.vue'
        ),
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
        checkPermissions(to, from, next);
      },
      meta: {
        navbarType: 'light',
        permissions: scanToPayPermission,
      },
    },
    {
      path: '/dinamic-payments',
      name: 'DinamicPayments',
      component: () =>
        import(
          /* webpackChunkName: "RemotePayments.vue" */
          '@/views/Sales/DinamicsPayments.vue'
        ),
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
        checkPermissions(to, from, next);
      },
      meta: {
        navbarType: 'light',
        permissions: dinamicPaymentPermission,
      },
    }
  ],
};
export default transactionHistoryRoute;
