











import logo from '@/assets/images/logo-white.svg';
import Vue from 'vue';

export default Vue.extend({
  name: 'LandingLayout',
  data: () => ({
    logo,
  }),
});
