import DatesFormat from '@/util/classes/datesFormat';

const dateFormat: DatesFormat = new DatesFormat();

export const moneyCents = (money: number): string => {
  money = money / 100;
  const formatter = new Intl.NumberFormat('en-US',
    { style: 'currency', currency: 'USD', }
  );
  return formatter.format(money);
};

export const money = (money: number): string => {
  const formatter = new Intl.NumberFormat('en-US',
    { style: 'currency', currency: 'USD', }
  );
  return formatter.format(money);
};

export const dateTime = (date: string): string => {
  return dateFormat.formatDateTime(date);
};
