<template>
  <ul v-if="companyHost === 'teip'" class="nav">
    <li>
      <a :href="helpRedirect()" target="_blank">
        Ayuda
      </a>
    </li>
  </ul>
</template>
<script>
import Vue from 'vue';
import { companyHost } from '@/consts/companyHost';

export default Vue.extend({
  name: 'TOptions',
  data: () => ({
    companyHost,
  }),
  methods: {
    helpRedirect() {
      const website = process.env.VUE_APP_WEBSITE_URL || 'https://dev.teip.io';
      return `${website}/me/support`;
    },
  },
});
</script>

<style lang="scss" scoped>
ul.nav {
  @apply md:space-x-8;
  li:not(:first-child) {
    border-top: 1px solid #d3d9de;
    @include medium {
      border-top: 0px;
    }
  }
  li {
    @apply py-2;
    a {
      @apply py-2 block text-center text-token-neutral-on-fill-highest-night;
    }
  }
}
</style>
