export const teipTranslation = {
  teip: {
    name: 'teip',
    menu: {
      company: {
        plural: { formal: 'Empresas', informal: 'empresas', },
        singular: { formal: 'Empresa', informal: 'empresa', },
      },
      commerce: {
        plural: { formal: 'Comercios', informal: 'comercios', },
        singular: { formal: 'Comercio', informal: 'comercio', },
      },
    },
    publicView: {
      landing: {
        cn: '@:menu.company.singular.formal desconocida',
        indication: '@:menu.company.singular.formal',
      },
      forgotPassword: {
        setupPassword: {
          cn: '@:menu.company.singular.formal desconocida',
          indication: '@:menu.company.plural.formal',
        },
      },
    },
  },
};
