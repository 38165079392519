import { render, staticRenderFns } from "./DashboardNavbar.vue?vue&type=template&id=6035397d&scoped=true&"
import script from "./DashboardNavbar.vue?vue&type=script&lang=ts&"
export * from "./DashboardNavbar.vue?vue&type=script&lang=ts&"
import style0 from "./DashboardNavbar.vue?vue&type=style&index=0&lang=css&"
import style1 from "./DashboardNavbar.vue?vue&type=style&index=1&id=6035397d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6035397d",
  null
  
)

export default component.exports