import {
  CompanyUserParams,
  CompanyUserState
} from '@/store/interfaces/companyUser';
import userType from '@/store/types/companyUser';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '../interfaces/rootState';

const namespaced = true;

const initialState = (): CompanyUserState => {
  return {
    companyUser: {
      id: '',
      permission_group_id: '',
      user_id: '',
      user: {
        id: '',
        email: '',
        confirmed: false,
        profile: {
          id: '',
          first_name: '',
          last_name: '',
        },
      },
    },
  };
};

const state: CompanyUserState = initialState();

const getters: GetterTree<CompanyUserState, RootState> = {
  [userType.getters.GET_SINGLE_COMPANY_USER]: state => {
    return state.companyUser;
  },
};
const mutations: MutationTree<CompanyUserState> = {
  [userType.mutations.SET_SINGLE_COMPANY_USER]: (state, user: CompanyUserParams) => {
    state.companyUser = user;
  },
};
const actions: ActionTree<CompanyUserState, RootState> = {
  [userType.actions.SEARCH_USER]: (_, queryParams = '') => {
    return new Promise((resolve, reject) => {
      http
        .get(`companies/company/users?${queryParams}`)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.SINGLE_COMPANY_USER]: ({ commit, }, user_id) => {
    return new Promise((resolve, reject) => {
      http
        .get(`companies/company/company_users/${user_id}`)
        .then((res: AxiosResponse) => {
          commit(userType.mutations.SET_SINGLE_COMPANY_USER, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.UPDATE_COMPANY_USER]: ({ commit, }, staffUser) => {
    const { user_id, data, } = staffUser;
    const dataToSend = {
      data,
    };
    return new Promise((resolve, reject) => {
      http
        .put(`companies/company/company_users/${user_id}`, dataToSend)
        .then((res: AxiosResponse) => {
          commit(userType.mutations.SET_SINGLE_COMPANY_USER, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.SAVE_COMPANY_USER]: ({ commit, }, data) => {
    return new Promise((resolve, reject) => {
      http
        .post('companies/company/company_users', { data, })
        .then((res: AxiosResponse) => {
          commit(userType.mutations.SET_SINGLE_COMPANY_USER, res.data.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [userType.actions.DELETE_COMPANY_USER]: (_, id) => {
    return new Promise((resolve, reject) => {
      http
        .delete(`companies/company/company_users/${id}`)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
