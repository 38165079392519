/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ifNotAuthenticated } from '../routeValidation';
import AuthenticationLayout from '@/views/Layout/AuthenticationLayout.vue';
const setupPassword = () =>
  import(/* webpackChunkName: "SetupPassword" */ '@/views/ForgotPassword/setupPassword.vue');

export default {
  path: '/setup-password',
  component: AuthenticationLayout,
  beforeEnter: ifNotAuthenticated,
  children: [
    {
      path: '/setup-password/:code',
      name: 'setup-password',
      component: setupPassword,
    }
  ],
};
