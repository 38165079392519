import BaseInput from '@/components/Argon/Inputs/BaseInput.vue';
import BaseDropdown from '@/components/Argon/BaseDropdown.vue';
import Modal from '@/components/Argon/Modal.vue';
import StatsCard from '@/components/Argon/Cards/StatsCard.vue';
import BaseButton from '@/components/Argon/BaseButton.vue';
import Badge from '@/components/Argon/Badge.vue';
import RouteBreadcrumb from '@/components/Argon/Breadcrumb/RouteBreadcrumb';
import BaseCheckbox from '@/components/Argon/Inputs/BaseCheckbox.vue';
import BaseSwitch from '@/components/Argon/BaseSwitch.vue';
import BaseRadio from '@/components/Argon/Inputs/BaseRadio';
import BaseProgress from '@/components/Argon/BaseProgress';
import BasePagination from '@/components/Argon/BasePagination';
import BaseAlert from '@/components/Argon/BaseAlert';
import BaseNav from '@/components/Argon/Navbar/BaseNav';
import BaseHeader from '@/components/Argon/BaseHeader';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { Input, Tooltip, Popover } from 'element-ui';
import LoadingPanel from '@/components/Argon/LoadingPanel.vue';
import TeipForm from '@/components/Reusable/teipForm.vue';

/**
 * teip globals components
 */
import HeaderBase from '@/components/teip-globals/HeaderBase.vue';
import HeaderBaseLink from '@/components/teip-globals/HeaderBaseLink.vue';

const GlobalComponents = {
  install (Vue) {
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(Modal.name, Modal);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component(RouteBreadcrumb.name, RouteBreadcrumb);
    Vue.component('el-input', Input);
    Vue.component('validation-provider', ValidationProvider);
    Vue.component('validation-observer', ValidationObserver);
    Vue.component('loading-panel', LoadingPanel);
    Vue.component('teip-form', TeipForm);
    Vue.use(Tooltip);
    Vue.use(Popover);

    // Components globals for teip
    Vue.component('header-base', HeaderBase);
    Vue.component('header-base-link', HeaderBaseLink);
  },
};

export default GlobalComponents;
