




import Vue from 'vue';
import tDesktop from './tDesktop.vue';

export default Vue.extend({
  components: { tDesktop, },
  name: 'NavBar',
});
