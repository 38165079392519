































import circleDashboard from '@/assets/images/screen-dashboard-circle.png';
import { companyHost } from '@/consts/companyHost';
import Vue from 'vue';

export default Vue.extend({
  name: 'Landing',
  data() {
    return {
      cn: '',
      circleDashboard,
      companyHost,
    };
  },
  created() {
    this.cn = `${this.$t('publicView.landing.cn')}`;
  },
  mounted() {
    if (this.$route.query.cn) {
      this.cn = this.$route.query.cn as string;
    }
  },
  methods: {
    goDashboard() {
      this.$router.push({
        name: 'home',
        query: { hasNewCompany: '1', },
      });
    },
  },
});
