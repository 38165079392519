export default {
  mutations: {
    SET_REPORT: 'SET_REPORT',
    SET_WEEKLY_REPORT: 'SET_WEEKLY_REPORT',
    SET_SALES_STATISTICS: 'SET_SALES_STATISTICS',
  },
  getters: {
    GET_REPORT: 'GET_REPORT',
    GET_WEEKLY_REPORT: 'GET_WEEKLY_REPORT',
    GET_SALES_STATISTICS: 'GET_SALES_STATISTICS',
  },
  actions: {
    DOWNLOAD_VOUCHER: 'DOWNLOAD_VOUCHER',
    RESENT_EMAIL: 'RESENT_EMAIL',
    SALES_REPORT: 'SALES_REPORT',
    SALES_STATISTICS: 'SALES_STATISTICS',
    DOWNLOAD_CSV: 'DOWNLOAD_CSV',
    SAVE_DINAMIC_PAYMENTS: 'SAVE_DINAMIC_PAYMENTS',
    UPDATE_DINAMIC_PAYMENTS: 'UPDATE_DINAMIC_PAYMENTS',
  },
};
