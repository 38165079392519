/* eslint-disable @typescript-eslint/no-explicit-any */

import hostConfig from '@/config/index.json';
import { companyHost } from '@/consts/companyHost';
import i18n from '@/i18n';
import { RootState } from '@/store/interfaces/rootState';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { ConfigurationState, iCompanyConfiguration, ihostConfig } from '../interfaces/configurations';
import configurations from '../types/configurations';

const namespaced = true;

const state: ConfigurationState = {
  hostConfig,
};

const getters: GetterTree<ConfigurationState, RootState> = {
  [configurations.getters.GET_HOST_CONFIGURATION]: state => {
    return state.hostConfig;
  },
};
const mutations: MutationTree<ConfigurationState> = {
  [configurations.mutations.SET_I18N_LOCAL_MESSAGE]: (_state, config: { data: iCompanyConfiguration }) => {
    const commerceTranslation = config.data.translations.commerce;
    const companyTranslation = config.data.translations.company;

    const translations = {
      commerce: {
        plural: {
          formal: commerceTranslation.plural,
          informal: commerceTranslation.plural.toLowerCase(),
        },
        singular: {
          formal: commerceTranslation.singular,
          informal: commerceTranslation.singular.toLowerCase(),
        },
      },
      company: {
        plural: {
          formal: companyTranslation.plural,
          informal: companyTranslation.plural.toLowerCase(),
        },
        singular: {
          formal: companyTranslation.singular,
          informal: companyTranslation.singular.toLowerCase(),
        },
      },
    };

    const newMessages = {
      teip: {
        name: 'teip',
        menu: {
          commerces: {
            plural: translations.commerce.plural.formal,
            singular: translations.commerce.singular.formal,
          },
          companies: {
            plural: translations.company.plural.formal,
            singular: translations.company.singular.formal,
          },
        },
      },
      [i18n.locale]: {
        name: i18n.locale,
        menu: translations,
      },
    };

    i18n.setLocaleMessage(i18n.locale, { ...newMessages[i18n.locale], });

    window.localStorage.setItem('company-translations', JSON.stringify(i18n.messages));
  },
  [configurations.mutations.SET_HOST_CONFIGURATION]: (state, config: ihostConfig) => {
    state.hostConfig = {
      ...hostConfig,
      ...config,
      globals: {
        ...hostConfig.globals,
        ...config.globals,
      },
      transactions: {
        ...hostConfig.transactions,
        ...config.transactions,
      },
    };
  },
};
const actions: ActionTree<ConfigurationState, RootState> = {
  [configurations.actions.HOST_CONFIGURATION]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      const url = `https://assets.teip.io/hosts/${companyHost}/config-biz.json`;
      axios
        .get(url)
        .then((res: AxiosResponse<ihostConfig>) => {
          commit(configurations.mutations.SET_HOST_CONFIGURATION, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
