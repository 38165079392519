



















import { companyHost } from '@/consts/companyHost';
import { allowAny } from '@/store/interfaces/generic';
import authType from '@/store/types/auth';
import { TGlueItemList } from 'glue-components';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'DashboardNavbar',
  data() {
    return {
      companyHost,
      company: '',
      options: [
        {
          leftIcon: 'user',
          title: 'Mi perfil',
          to: '/profile',
        },
        {
          leftIcon: 'settings',
          title: 'Configuración',
          to: '/configuration',
        },
        {
          leftIcon: 'transfer-out',
          title: 'Cerrar sesión',
        }
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: `authModule/${authType.getters.GET_AUTH_USER}`,
      isAuth: `authModule/${authType.getters.IS_AUTHENTICATED}`,
      companySelected: `authModule/${authType.getters.GET_COMPANY}`,
      permission: `authModule/${authType.getters.PERMISSIONS}`,
    }),
    dropdown(): TGlueItemList {
      const fullName = `${this.currentUser.profile.first_name} ${this.currentUser.profile.last_name}`;

      const initials = fullName
        .split(' ')
        .map((n) => n[0])
        .slice(0, 2)
        .join('');

      return {
        id: '',
        title: fullName,
        avatar: initials,
        rightIcon: 'chevron-down',
      };
    },
  },
  methods: {
    clickSidebar(): void {
      this.$emit('click-sidebar');
    },

    async logout(): Promise<void> {
      await this.$store.dispatch(`authModule/${authType.actions.AUTH_LOGOUT}`);
      this.$router.push({
        name: 'Login',
      });
    },
    getItem(item: TGlueItemList): void {
      if (item.title === 'Cerrar sesión') {
        this.logout();
      }
    },
  },
  mounted(): void {
    if (this.$route.query.hasNewCompany === '1') {
      this.$store.dispatch(`authModule/${authType.actions.RE_FETCH_USER_INFO}`);
    }

    if (this.currentUser?.companies && this.currentUser?.companies.length > 0) {
      this.$data.company = this.companySelected;
      (this.$refs
        .userPermission as allowAny).$data.select = this.companySelected;
    }
  },
  watch: {
    company: {
      handler(val: string) {
        if (val) {
          this.$store.dispatch(
            `authModule/${authType.actions.SET_COMPANY}`,
            val
          );
        }
      },
    },
  },
});
