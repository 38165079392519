export default {
  mutations: {
    SET_BRANCHES_LIST: 'SET_BRANCHES_LIST',
    SET_BRANCHES_SIGNATURE: 'SET_BRANCHES_SIGNATURE',
  },
  getters: {
    GET_BRANCHES_LIST: 'GET_BRANCHES_LIST',
    GET_BRANCHES_SIGNATURE: 'GET_BRANCHES_SIGNATURE',
  },
  actions: {
    BRANCHES_LIST: 'BRANCHES_LIST',
    BRANCHES_SIGNATURE: 'BRANCHES_SIGNATURE',
  },
};
