











import Vue from 'vue';
import tOptions from './tOptions.vue';
export default Vue.extend({
  name: 'NavBar',
  components: {
    tOptions,
  },
  props: {
    showModal: Boolean,
  },
  computed: {
    syncedShowModal: {
      get(): boolean {
        return this.showModal;
      },
      set(val: boolean) {
        this.$emit('update:showModal', val);
      },
    },
  },
  methods: {
    closeModal() {
      this.syncedShowModal = false;
    },
  },
});
