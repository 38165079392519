import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/store/interfaces/rootState';
import branchesType from '@/store/types/branches';
import { http } from '@/util/HTTP';
import { AxiosResponse, AxiosError } from 'axios';
import { BrancheSignature, BranchState } from '@/store/interfaces/branches';
import { responseCollection } from '../interfaces/generic';
import { IdName } from '../interfaces/commerce';

const namespaced = true;
const initialState = (): BranchState => {
  return {
    branches: {
      data: [],
      pagination: {},
    },
    signatures: {
      data: [],
      pagination: {},
    },
  };
};

const state: BranchState = initialState();
const getters: GetterTree<BranchState, RootState> = {
  [branchesType.getters.GET_BRANCHES_LIST]: state => {
    return state.branches;
  },
  [branchesType.getters.GET_BRANCHES_SIGNATURE]: state => {
    return state.signatures;
  },
};

const mutations: MutationTree<BranchState> = {
  [branchesType.mutations.SET_BRANCHES_LIST]: (
    state,
    branch: responseCollection<IdName>
  ) => {
    state.branches.data = branch.data;
    state.branches.pagination = branch.meta.pagination;
  },
  [branchesType.mutations.SET_BRANCHES_SIGNATURE]: (
    state,
    signatures: responseCollection<BrancheSignature>
  ) => {
    state.signatures.data = signatures.data;
    state.signatures.pagination = signatures.meta.pagination;
  },
};
const actions: ActionTree<BranchState, RootState> = {
  [branchesType.actions.BRANCHES_LIST]: ({ commit, }, { commerce_id, params, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`companies/company/branches?commerce_id_eq=${commerce_id}&${params}`)
        .then((res: AxiosResponse) => {
          commit(branchesType.mutations.SET_BRANCHES_LIST, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [branchesType.actions.BRANCHES_SIGNATURE]: ({ commit, }) => {
    return new Promise((resolve, reject) => {
      http
        .get('companies/company/branches/signatures')
        .then((res: AxiosResponse) => {
          commit(branchesType.mutations.SET_BRANCHES_SIGNATURE, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
