import { RouteConfig } from 'vue-router';
import { ifAuthenticated } from '../routeValidation';
import AuthenticationLayout from '@/views/Layout/landingLayout.vue';
import Landing from '@/views/landing/Landing.vue';

const landingRoute: RouteConfig = {
  path: '/Landing',
  component: AuthenticationLayout,
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: '/landing-page',
      name: 'Landing',
      component: Landing,
    }
  ],
};
export default landingRoute;
