import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { LoginRoutes } from './menus/login';
import transactionHistoryRoute from './menus/Sales';
import UnlockAccount from './menus/unlockAccount';
import ForgotPassword from './menus/forgotPassword';
import setupPassword from './menus/setPassword';
import landingRoute from './menus/Landing';
import Profile from './menus/profile';
import Staff from './menus/staff';
import Support from './menus/Support';
import remotePayment from './menus/remotePayment';
import { ifAuthenticated } from './routeValidation';
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import NotAllowed from '@/views/GeneralViews/NotAllowedPage.vue';
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';
import store from '@/store';
import auth from '@/store/types/auth';
Vue.use(VueRouter);

const homePages: RouteConfig = {
  path: '/',
  component: DashboardLayout,
  beforeEnter: ifAuthenticated,
  children: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home/index.vue'),
    }
  ],
};

const notAllowed: RouteConfig = {
  path: '/403',
  name: 'not-allowed',
  component: NotAllowed,
  beforeEnter: ifAuthenticated,
  meta: {
    navbarType: 'light',
  },
};

const notFoundPages: RouteConfig = {
  path: '*',
  name: 'NotFound',
  component: NotFound,
  meta: {
    navbarType: 'light',
  },
};

const redirectWelcome: RouteConfig = {
  path: '/welcome',
  redirect: () => {
    if (store.getters[`authModule/${auth.getters.IS_AUTHENTICATED}`]) {
      return 'landing-page';
    }
    return 'login';
  },
};

const routes: Array<RouteConfig> = [
  LoginRoutes,
  ForgotPassword,
  UnlockAccount,
  setupPassword,
  homePages,
  Profile,
  Staff,
  notFoundPages,
  notAllowed,
  transactionHistoryRoute,
  Support,
  remotePayment,
  landingRoute,
  redirectWelcome
];

const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash, };
    }
    return { x: 0, y: 0, };
  },
});

export default router;
