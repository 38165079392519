import { NavigationGuardNext, RouteConfig, Route } from 'vue-router';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';

import { checkPermissions } from '../routeValidation';
import { supportPermission } from './permissions';

const SupportForm = () =>
  import(
    /* webpackChunkName: "SupportForm" */
    '@/views/Support/New.vue'
  );

const transactionHistoryRoute: RouteConfig = {
  path: '/',
  component: DashboardLayout,
  children: [
    {
      path: '/support',
      name: 'SupportForm',
      component: SupportForm,
      meta: {
        navbarType: 'light',
        permissions: supportPermission,
      },
      beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
        checkPermissions(to, from, next);
      },
    }
  ],
};
export default transactionHistoryRoute;
