import { render, staticRenderFns } from "./Landing.vue?vue&type=template&id=4d92a3a1&scoped=true&"
import script from "./Landing.vue?vue&type=script&lang=ts&"
export * from "./Landing.vue?vue&type=script&lang=ts&"
import style0 from "./Landing.vue?vue&type=style&index=0&id=4d92a3a1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d92a3a1",
  null
  
)

export default component.exports