export default {
  mutations: {
    SET_PERMISSIONS_GROUP_LIST: 'SET_PERMISSIONS_GROUP_LIST',
  },
  getters: {
    GET_PERMISSIONS_GROUP_LIST: 'GET_PERMISSIONS_GROUP_LIST',
  },
  actions: {
    PERMISSION_GROUP_LIST: 'PERMISSION_GROUP_LIST',
  },
};
