import { RootState } from '@/store/interfaces/rootState';
import {
  TransactionState
} from '@/store/interfaces/transactions';
import transactionsTypes from '@/store/types/transactions';
import { http } from '@/util/HTTP';
import { AxiosError, AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

const namespaced = true;
const initialState = (): TransactionState => {
  return {
    report: [],
    weekly_report: [],
    statistics: {
      total_cents: 0,
      total_currency: '',
      ticket_average_cents: 0,
      ticket_average_currency: '',
      daily_average_cents: 0,
      daily_average_currency: '',
      transactions_count: 0,
      daily_transactions_count: 0,
      users_count: 0,
      created_at: '',
      previous_period_total_cents: '0',
    },
  };
};

const state: TransactionState = initialState();
const getters: GetterTree<TransactionState, RootState> = {
  [transactionsTypes.getters.GET_REPORT]: state => {
    return state.report;
  },
  [transactionsTypes.getters.GET_WEEKLY_REPORT]: state => {
    return state.weekly_report;
  },
  [transactionsTypes.getters.GET_SALES_STATISTICS]: state => {
    return state.statistics;
  },
};

const mutations: MutationTree<TransactionState> = {
  reset: state => {
    const initialStateFields = initialState();
    Object.keys(initialStateFields).forEach(key => {
      state[key] = initialStateFields[key];
    });
  },
  [transactionsTypes.mutations.SET_WEEKLY_REPORT]: (
    state,
    report
  ) => {
    state.weekly_report = report.data;
  },
  [transactionsTypes.mutations.SET_SALES_STATISTICS]: (
    state,
    salesStatistics
  ) => {
    state.statistics = salesStatistics.data[0];
  },
  [transactionsTypes.mutations.SET_REPORT]: (
    state,
    report
  ) => {
    state.report = report.data;
  },
};
const actions: ActionTree<TransactionState, RootState> = {
  [transactionsTypes.actions.DOWNLOAD_VOUCHER]: (_, { id, label, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`companies/company/payment_requests/${id}.pdf`, {
          responseType: 'blob',
        })
        .then((res: AxiosResponse) => {
          const blob = new Blob([res.data], { type: 'application/pdf', });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [transactionsTypes.actions.DOWNLOAD_CSV]: (_, { label, query, }) => {
    return new Promise((resolve, reject) => {
      http
        .get(`companies/company/payment_requests.csv?${query}`, {
          responseType: 'blob',
        })
        .then((res: AxiosResponse) => {
          const blob = new Blob([res.data], { type: 'application/csv', });
          saveAs(blob, label);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [transactionsTypes.actions.SAVE_DINAMIC_PAYMENTS]: (_, data) => {
    return new Promise((resolve, reject) => {
      http
        .post('companies/company/order_templates', { data, })
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [transactionsTypes.actions.UPDATE_DINAMIC_PAYMENTS]: (_, { data, id, }) => {
    return new Promise((resolve, reject) => {
      http
        .put(`companies/company/order_templates/${id}`, { data, })
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [transactionsTypes.actions.RESENT_EMAIL]: (_, id: string) => {
    return new Promise((resolve, reject) => {
      http
        .get(`companies/company/payment_requests/${id}/resend_payment_confirmation`)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [transactionsTypes.actions.SALES_REPORT]: (
    { commit, },
    { queryString, isWeekly, }
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(`companies/company/sales_summaries?${queryString}`)
        .then((res: AxiosResponse) => {
          if (isWeekly) {
            commit(transactionsTypes.mutations.SET_WEEKLY_REPORT, res.data);
          } else {
            commit(transactionsTypes.mutations.SET_REPORT, res.data);
          }
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
  [transactionsTypes.actions.SALES_STATISTICS]: (
    { commit, },
    queryString = ''
  ) => {
    return new Promise((resolve, reject) => {
      http
        .get(`companies/company/sales_summaries/statistics?${queryString}`)
        .then((res: AxiosResponse) => {
          commit(transactionsTypes.mutations.SET_SALES_STATISTICS, res.data);
          resolve(res);
        })
        .catch((error: AxiosError) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
